import * as React from "react"
import { Link } from "gatsby"
import Nav2 from "../components/nav2"
import Footer from "../components/footer"

const SuccessPage = () => {
  return (
    <div pageTitle="Success">
      <Nav2 />
      <main className="nomal-page">
        <h1>送信ありがとうございました。</h1>
        <p>
          <br />
          送信は正常に完了しました。
          <br />
          <br />
          <br />
          <br />
          <Link to="/" className="underline">ホームに戻る</Link>.<br/>
        </p>
      </main>
      <Footer />
    </div>
  )
}

export default SuccessPage
